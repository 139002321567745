import { IconMenu2, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";

export default function Landing5Navbar() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [smallIcons, setSmallIcons] = useState(window.innerWidth > 650);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setSmallIcons(window.innerWidth > 650);
        })
    }, []);

    return (
        <>
            {smallIcons
                ? <div
                    className="landing5navbar-conatiner"
                    style={{ marginTop: window.location.pathname === '/' ? '50px' : '0px' }}
                >
                    <a href='/'>
                        <div>
                            <img
                                className='landing5navbar-logo-image'
                                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                            />
                        </div>
                    </a>
                    <div className="landing5navbar-options">
                        <a href='/sample' className='landing4-header-menu-item-link'>
                            <div className='landing4-header-menu-item'>
                                Sample
                            </div>
                        </a>
                        <a href='/demo' className='landing4-header-menu-item-link'>
                            <div className='landing4-header-menu-item'>
                                Demo
                            </div>
                        </a>
                        <a href='/careers' className='landing4-header-menu-item-link'>
                            <div className='landing4-header-menu-item'>
                                Careers
                            </div>
                        </a>
                        <a href='/connect' className='landing4-header-menu-item-link'>
                            <div className='landing4-header-menu-item'>
                                Connect
                            </div>
                        </a>
                        <a href='https://app.bobyard.com/login' className='landing4-header-menu-item-link'>
                            <div>
                                <button className='landing4-header-menu-login-button'>
                                    Login
                                </button>
                            </div>
                        </a>
                        <a href='/demo' className='landing4-header-menu-item-link'>
                            <div>
                                <button className='landing4-header-menu-button'>
                                    Try now
                                </button>
                            </div>
                        </a>
                    </div>
                </div>
                : <>
                    <div className="landing5navbar-conatiner"
                        style={{ marginTop: window.location.pathname === '/' ? '50px' : '0px' }}
                    >
                        <a href='/'>
                            <div>
                                <img
                                    className='landing5navbar-logo-image'
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                                />
                            </div>
                        </a>
                        <div
                            className='landing5navbar-menu-icon'
                            onClick={() => setShowMobileMenu(!showMobileMenu)}
                        >
                            {showMobileMenu
                                ? <IconX size={30} color='#000000' />
                                : <IconMenu2 size={30} color='#000000' />
                            }
                        </div>
                    </div>

                    {showMobileMenu &&
                        <div className='landing5navbar-mobile-menu'>
                            <a href='/demo' className='landing4-header-menu-item-link'>
                                <div className='landing4-header-menu-item'>
                                    Demo
                                </div>
                            </a>
                            <a href='/careers' className='landing4-header-menu-item-link'>
                                <div className='landing4-header-menu-item'>
                                    Careers
                                </div>
                            </a>
                            <a href='/connect' className='landing4-header-menu-item-link'>
                                <div className='landing4-header-menu-item'>
                                    Connect
                                </div>
                            </a>
                            <a href='/demo' className='landing4-header-menu-item-link'>
                                <div>
                                    <button className='landing4-header-menu-button'>
                                        Try now
                                    </button>
                                </div>
                            </a>
                        </div>
                    }
                </>
            }
        </>
    )
}