import "../styles/HiringBar.css"

export default function HiringBar({ user }) {

    if (window.location.pathname === "/") {
        return (
            <a href="/careers" className="hiringbar-text">
                <div className="hiringbar-container">
                    <div>
                        We are hiring CV Engineers & Account Executives!&nbsp;
                        <a href="/careers" className="hiringbar-link">See postings</a>
                    </div>
                </div>
            </a>
        )
    } else {
        return null
    }
}