import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";

import ReactGA from "react-ga4";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './components/NavBar';
import ProjectMap from './pages/ProjectMap';
import ContractorsInfo from './homepage/ContractorsInfo';
import LandingPage from './homepage/LandingPage';

import Login from './homepage/login';
import Register from './homepage/register';
import Logout from './homepage/logout';
import ContractorRegister from './homepage/ContractorRegister';

import Dashboard from './HomeOwnerPages/Dashboard';
import EditProjectDetail from './HomeOwnerPages/EditProjectDetail';

import ContractorDashboard from './ContractorPages/ContractorDashboard';
import ContractorProject from './ContractorPages/ContractorProject';
import ContractorOpenhouse from './ContractorPages/ContractorOpenhouse';

import useUser from './helper/useUser';
import useToken from './helper/useToken';

import OpenHouse from './HomeOwnerPages/OpenHouse';
import ContractorBid from './ContractorPages/ContractorBid';
import ProjectBids from './HomeOwnerPages/ProjectBids';
import ContractorViewProject from './ContractorPages/ContractorViewProject';
import ContractorMap from './ContractorPages/ContractorMap';
import GenerateContract from './HomeOwnerPages/GenerateContract';
import UploadFiles from './HomeOwnerPages/UploadFiles';
import ContractorViewFiles from './ContractorPages/ContractorViewFiles';
import ProjectDetail from './HomeOwnerPages/ProjectDetail';
import PermitMap from './pages/PermitMap';
import ContractorSign from './ContractorPages/ContractorSign';
import ProjectSignContract from './HomeOwnerPages/ProjectSignContract';
import ProjectViewContract from './HomeOwnerPages/ProjectViewContract';
import ContractorViewContracts from './ContractorPages/ContractorViewContracts';
import TemplateList from './HomeOwnerPages/TemplateList';
import TemplateDetails from './HomeOwnerPages/TemplateDetail';
import BidEdits from './HomeOwnerPages/BidEdits';
import ContractorProfile from './ContractorPages/ContractorProfile';
import ContractorList from './HomeOwnerPages/ContractorList';
import ContractorPublicProfile from './HomeOwnerPages/ContractorPublicProfile';
import ContractorViewFinalized from './ContractorPages/ContractorViewFinalized';
import RegisterChoice from './homepage/RegisterChoice';
import HomeownerBid from './HomeOwnerPages/HomeownerBid';
import AddedBidsList from './HomeOwnerPages/AddedBidsList';
import ContractorTakeoff from './ContractorPages/ContractorTakeoff';
import ContractorSOW from './ContractorPages/ContractorCreateProjectPages/ContractorSOW';
import ContractorCreatedProjectDashboard from './ContractorPages/ContractorCreateProjectPages/ContractorCreatedProjectDashboard';
import About from './homepage/About';
import OutsideContractorSign from './pages/OutsideContractorSign';
import ContractorCreateContract from './ContractorPages/ContractorCreateProjectPages/ContractorCreateContract';
import ContractorProjectSign from './ContractorPages/ContractorCreateProjectPages/ContractorProjectSign';
import ContractorProjectOwnerSign from './ContractorPages/ContractorCreateProjectPages/ContractorProjectOwnerSign';
import ContractorProjectViewContracts from './ContractorPages/ContractorCreateProjectPages/ContractorProjectViewContracts';
import ContractorProjectTemplateList from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTemplates';
import ContractorProjectTemplateDetail from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTemplateDetail';
import Support from './homepage/Support';
import Demo from './homepage/Demo';
import ExpertReview from './HomeOwnerPages/ExpertReview';
import OutsideContractorViewContract from './pages/OutsideContractorViewContract';
import AdminProjectComment from './AdminPages/AdminProjectComment';
import PublicMap from './pages/PublicMap';
import QuestionList from './HomeOwnerPages/ArchitectPages/QuestionsList';
import QuestionDetail from './HomeOwnerPages/ArchitectPages/QuestionDetail';
import ArchitectList from './HomeOwnerPages/ArchitectPages/ArchitectList';
import ArchitectDetail from './HomeOwnerPages/ArchitectPages/ArchitectDetail';
import ArchitectRegister from './homepage/ArchitectRegister';
import ArchitectDashboard from './Architects/ArchitectDashboard';
import ArchitectViewQuestion from './Architects/ArchitectViewQuestion';
import ContractorBidTrades from './ContractorPages/ContractorBidTrades';
import ContractorBidSections from './ContractorPages/ContractorEstimate';
import ContractorBidTradesSections from './ContractorPages/ContractorBidTradesSections';
import BidEditsTrades from './HomeOwnerPages/BidEditsTrades';
import ContractorProjectTakeoff from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTakeoff';
import ContractorProjectFiles from './ContractorPages/ContractorCreateProjectPages/ContractorProjectFiles';
import ContractorProjectBid from './ContractorPages/ContractorCreateProjectPages/ContractorProjectBid';
import ProjectProfile from './HomeOwnerPages/ProjectProfile';
import ContractorEstimate from './ContractorPages/ContractorEstimate';
import ProjectEstimates from './HomeOwnerPages/ProjectEstimates';
import ContractorFinalize from './ContractorPages/ContractorFinalize';
import ViewFinalizedSOW from './HomeOwnerPages/ViewFinalizedSOW';
import CompareBids from './HomeOwnerPages/CompareBids';
import ContractorTemplateList from './ContractorPages/ContractorTemplateList';
import ContractorTemplateDetails from './ContractorPages/ContractorTemplateDetail';
import ProjectContractorCreateContract from './ContractorPages/ProjectContractorCreateContract';
import ProjectContractorSign from './ContractorPages/ProjectContractorSign';
import ContractorViewprofile from './ContractorPages/ContractorViewProfile';
import ContractorProjectSubTradeBid from './ContractorPages/ContractorCreateProjectPages/ContractorProjectSubTradeBid';
import ContractorProjectTakeoffTrades from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTakeoffTrades';
import ContractorProjectFilesOwner from './ContractorPages/ContractorCreateProjectPages/ContractorProjectFilesOwner';
import ContractorSOWOwnerView from './ContractorPages/ContractorCreateProjectPages/ContractorSOWOwnerView';
import ContractorProjectSettings from './ContractorPages/ContractorCreateProjectPages/ContractorProjectSettings';
import ContractorProjectChangeOrders from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrders';
import ContractorProjectChangeOrder from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrderPages/ContractorProjectChangeOrder';
import ContractorProjectChangeOrderBid from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrderPages/ContractorProjectChangeOrderBid';
import ContractorProjectChangeOrderReview from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrderPages/ContractorProjectChangeOrderReview';
import ContractorProjectChangeOrderSubtrades from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrderPages/ContractorProjectChangeOrderSubtrades';
import ContractorProjectChangeOrderSubtradesReview from './ContractorPages/ContractorCreateProjectPages/ContractorProjectChangeOrderPages/ContractorProjectChangeOrderSubtradesReview';
import Checkout from './homepage/ContractorCheckout';
import ContractorCheckout from './homepage/ContractorCheckout';
import ContractorRegisterEarlyAccess from './homepage/ContractorRegisterEarlyAccess';
import ContractorProjectTradeTemplates from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTradeTemplates';
import ContractorProjectTradeTemplateDetail from './ContractorPages/ContractorCreateProjectPages/ContractorProjectTradeTemplateDetail';
import ContractorProjectSubTradeBidOwnerView from './ContractorPages/ContractorCreateProjectPages/ContractorProjectSubTradeBidOwnerView';
import LandingPageContractor from './homepage/LandingPageContractor';
import ContractorsAbout from './homepage/ContractorsAbout';
import ContractorProjectBidOwnerView from './ContractorPages/ContractorCreateProjectPages/ContractorProjectBidOwnerView';
import ContractorAccount from './ContractorPages/ContractorAcount';
import ContractorProjectOwnerView from './ContractorPages/ContractorCreateProjectPages/ContractorProjectOwnerView';
import ContractorChangeOrders from './ContractorPages/ContractorChangeOrders';
import ContractorChangeOrder from './ContractorPages/ContractorChangeOrderPages/ContractorChangeOrder';
import ContractorChangeOrderBid from './ContractorPages/ContractorChangeOrderPages/ContractorChangeOrderBid';
import ContractorDocument from './ContractorPages/ContractorDocument';
import ContractorDocumentOwnerView from './ContractorPages/ContractorDocumentOwnerView';
import Landing2 from './homepage/Landing2';
import HiringBar from './components/HiringBar';
import Career from './homepage/Career';
import Landing3 from './homepage/Landing3';
import Landing4 from './homepage/Landing4';
import Career2 from './homepage/Career2';
import Demo2 from './homepage/Demo2';
import Contact from './homepage/Contact';
import Landing5 from './homepage/Landing5';
import LandingLogin from './homepage/LandingLogin';
import DemoRequest from './homepage/DemoRequest';

ReactGA.initialize("G-C198TR167Z");

function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [prevPath, setPrevPath] = useState(null);

  useEffect(() => {
    if (window.location.hostname.includes('bobyard')) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const ProtectedRoute = ({
    token,
    redirectPath = '/login',
  }) => {
    console.log(token);
    let loc = useLocation();

    if (!token) {
      if (loc.pathname != '/logout') { //dont want to redirect to logout after login
        setPrevPath(loc); //store desired url that will be followed after login
      }
      return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
  };

  return (
    <>
      <HiringBar />
      {/*<ToastContainer />*/}
      {/*<NavBar user={user} />*/}
      <Router>
        <Routes>
          {/*<Route
            path="/"
            element={<LandingPageContractor />}
          />*/}
          <Route
            path="/careers-old"
            element={<Career />}
          />
          <Route
            path="/careers"
            element={<Career2 />}
          />
          <Route
            path="/landing3"
            element={<Landing3 />}
          />
          <Route
            path="/landing4"
            element={<Landing4 />}
          />
          <Route
            path="/"
            element={<Landing5 />}
          />
          <Route
            path="/landing"
            element={<LandingPage />}
          />
          {/*<Route
            path="/about-us"
            element={<About />}
          />*/}
          <Route
            path="/about-us"
            element={<ContractorsAbout />}
          />
          <Route
            path="/sample"
            element={<DemoRequest />}
          />
          <Route
            path="/support"
            element={<Support />}
          />
          <Route
            path="/connect"
            element={<Contact />}
          />
          <Route
            path="/demo-old"
            element={<Demo />}
          />
          <Route
            path="/demo"
            element={<Demo2 />}
          />
          <Route
            path="/login"
            element={<LandingLogin />}
          />


          {/*<Route
            path="/map"
            element={<PublicMap />}
          />
          <Route
            path="/project-map"
            element={<ProjectMap user={user} />}
          />
          <Route
            path='/permit-map'
            element={<PermitMap user={user} />}
          />

          <Route
            path='/contractors-info'
            element={<ContractorsInfo />} //static page with link to contractor create account
          />
          <Route
            path='/contractors-register/:sessionID'
            element={<ContractorRegister user={user} />}
          />
          <Route
            path='/contractors'
            element={<ContractorList user={user} />}
          />
          <Route
            path='/contractor/:contractorUUID'
            element={<ContractorPublicProfile user={user} />}
          />

          <Route
            path='/sign-contract/:projectUUID'
            element={<OutsideContractorSign />}
          />
          <Route
            path='/view-contract/:projectUUID'
            element={<OutsideContractorViewContract />}
          />

          <Route
            path='/contractor-project-owner-view/:projectUUID'
            element={<ContractorProjectOwnerView />}
          />
          <Route
            path='/contractor-createdproject-owner-upload-files/:projectUUID'
            element={<ContractorProjectFilesOwner />}
          />
          <Route
            path='/contractor-createdproject-trade-bid-owner-view/:projectUUID'
            element={<ContractorProjectSubTradeBidOwnerView />}
          />
          <Route
            path='/contractor-createdproject-bid-owner-view/:projectUUID'
            element={<ContractorProjectBidOwnerView />}
          />
          <Route
            path='/contractor-createdproject-owner-sow/:projectUUID'
            element={<ContractorSOWOwnerView />}
          />
          <Route
            path='/contractor-document-owner-view/:projectUUID/'
            element={<ContractorDocumentOwnerView />}
          />
          <Route
            path='/contractor-createdproject-ownersign/:projectUUID'
            element={<ContractorProjectOwnerSign />}
          />
          <Route
            path='/contractor-createdproject-change-order-review/:projectUUID/version/:versionID'
            element={<ContractorProjectChangeOrderReview user={user} />}
          />
          <Route
            path='/contractor-createdproject-change-order-trades/:projectUUID/version/:versionID'
            element={<ContractorProjectChangeOrderSubtrades user={user} />}
          />
          <Route
            path='/contractor-createdproject-change-order-trades-review/:projectUUID/version/:versionID'
            element={<ContractorProjectChangeOrderSubtradesReview user={user} />}
          />

          <Route
            path="/register"
            element={<Register user={user} />}
          />
          <Route
            path="/register-choice"
            element={<RegisterChoice user={user} />}
          />
          <Route
            path="/register-architect"
            element={<ArchitectRegister user={user} />}
          />
          <Route
            path="/login"
            element={<Login setToken={setToken} setUser={setUser} user={user} prevPath={prevPath} />}
          />

          <Route
            path="/contractor-register-early-access/:UUID1/:UUID2"
            element={<ContractorRegisterEarlyAccess />}
          />

          <Route
            path="/contractor-register-early-access"
            element={<ContractorRegisterEarlyAccess user={user} setToken={setToken} setUser={setUser} />}
          />

          <Route
            path="/contractor-checkout"
            element={<ContractorCheckout user={user} />}
          />

          <Route element={<ProtectedRoute token={token} />}>
            <Route
              path="/dashboard"
              element={<Dashboard user={user} />}
            />
            <Route
              path="/project-detail/:projectUUID"
              element={<ProjectDetail user={user} />}
            />
            <Route
              path='/expert-review/:projectUUID'
              element={<ExpertReview user={user} />}
            />
            <Route
              path='/edit-project/:projectUUID'
              element={<EditProjectDetail user={user} />}
            />
            <Route
              path='/project-profile/:projectUUID'
              element={<ProjectProfile user={user} />}
            />
            <Route
              path='/open-house/:projectUUID'
              element={<OpenHouse user={user} />}
            />
            <Route
              path='/upload-files/:projectUUID'
              element={<UploadFiles user={user} />}
            />
            <Route
              path='/project-addedbids/:projectUUID'
              element={<AddedBidsList user={user} />}
            />
            <Route
              path='/project-add-bid/:projectUUID/:addedID'
              element={<HomeownerBid user={user} />}
            />
            <Route
              path='/project-estimates/:projectUUID'
              element={<ProjectEstimates user={user} />}
            />
            <Route
              path='/compare-bids/:projectUUID'
              element={<CompareBids user={user} />}
            />
            <Route
              path='/project-bids/:projectUUID'
              element={<ProjectBids user={user} />}
            />
            <Route
              path='/finalize-bid/:projectUUID'
              element={<BidEdits user={user} />}
            />
            <Route
              path='/finalize-bid-trades/:projectUUID'
              element={<BidEditsTrades user={user} />}
            />
            <Route
              path='/view-finalized-sow/:projectUUID/:contractorUUID'
              element={<ViewFinalizedSOW user={user} />}
            />
            <Route
              path='/project-generate-contract/:projectUUID'
              element={<GenerateContract user={user} />}
            />
            <Route
              path='project-sign-contract/:projectUUID'
              element={<ProjectSignContract user={user} />}
            />
            <Route
              path='/project-view-contract/:projectUUID'
              element={<ProjectViewContract user={user} />}
            />
            <Route
              path='/template-list/:projectUUID'
              element={<TemplateList user={user} />}
            />
            <Route
              path='/template-details/:projectUUID/template/:templateUUID'
              element={<TemplateDetails user={user} />}
            />
            <Route
              path='/questions-list/'
              element={<QuestionList user={user} />}
            />
            <Route
              path='/question-detail/:questionUUID'
              element={<QuestionDetail user={user} />}
            />
            <Route
              path='/architect-list/'
              element={<ArchitectList user={user} />}
            />
            <Route
              path='/architect-detail/:architectUUID'
              element={<ArchitectDetail user={user} />}
            />

            <Route
              path='/contractor-profile'
              element={<ContractorProfile user={user} />}
            />
            <Route
              path='/contractor-account'
              element={<ContractorAccount user={user} />}
            />
            <Route
              path='/contractor-dashboard'
              element={<ContractorDashboard user={user} />}
            />
            <Route
              path='/contractor-project/:projectUUID'
              element={<ContractorProject user={user} />}
            />
            <Route
              path='/contractor-files/:projectUUID'
              element={<ContractorViewFiles user={user} />}
            />
            <Route
              path='/contractor-openhouse/:projectUUID'
              element={<ContractorOpenhouse user={user} />}
            />
            <Route
              path='/contractor-view-profile/:projectUUID'
              element={<ContractorViewprofile user={user} />}
            />
            <Route
              path='/contractor-estimate/:projectUUID'
              element={<ContractorEstimate user={user} />}
            />
            <Route
              path='/contractor-finalize/:projectUUID'
              element={<ContractorFinalize user={user} />}
            />
            <Route
              path='/contractor-template-list/:projectUUID'
              element={<ContractorTemplateList user={user} />}
            />
            <Route
              path='/contractor-template-details/:projectUUID/template/:templateUUID'
              element={<ContractorTemplateDetails user={user} />}
            />
            <Route
              path='/contractor-template-details/:projectUUID/contractor-template/:templateID'
              element={<ContractorTemplateDetails user={user} />}
            />
            <Route
              path='/contractor-bid/:projectUUID'
              element={<ContractorBid user={user} />}
            />
            <Route
              path='/contractor-bid-trades/:projectUUID'
              element={<ContractorBidTrades user={user} />}
            />
            <Route
              path='/contractor-bid-sections/:projectUUID'
              element={<ContractorBidSections user={user} />}
            />
            <Route
              path='/contractor-bid-trades-sections/:projectUUID'
              element={<ContractorBidTradesSections user={user} />}
            />
            <Route
              path='/contractor-takeoff/:projectUUID'
              element={<ContractorTakeoff user={user} />}
            />
            <Route
              path='/contractor-view-finalized/:projectUUID'
              element={<ContractorViewFinalized user={user} />}
            />
            <Route
              path='/project-contractor-create-contract/:projectUUID'
              element={<ProjectContractorCreateContract user={user} />}
            />
            <Route
              path='/project-contractor-sign/:projectUUID'
              element={<ProjectContractorSign user={user} />}
            />
            <Route
              path='/project-contractor-changeorders/:projectUUID'
              element={<ContractorChangeOrders user={user} />}
            />
            <Route
              path='/project-contractor-changeorder/:projectUUID/version/:versionID'
              element={<ContractorChangeOrder user={user} />}
            />
            <Route
              path='/project-contractor-changeorder-bid/:projectUUID/version/:versionID'
              element={<ContractorChangeOrderBid user={user} />}
            />
            <Route
              path='/contractor-sign/:projectUUID'
              element={<ContractorSign user={user} />}
            />
            <Route
              path='/contractor-view-project/:projectUUID'
              element={<ContractorViewProject user={user} />}
            />
            <Route
              path='/contractor-view-contracts/:projectUUID'
              element={<ContractorViewContracts user={user} />}
            />
            <Route
              path='/contractor-map'
              element={<ContractorMap user={user} />}
            />
            <Route
              path='/contractor-createdproject/:projectUUID'
              element={<ContractorCreatedProjectDashboard user={user} />}
            />
            <Route
              path='/contractor-createproject/:projectUUID'
              element={<ContractorSOW user={user} />}
            />
            <Route
              path='/contractor-createdproject-settings/:projectUUID'
              element={<ContractorProjectSettings user={user} />}
            />
            <Route
              path='/contractor-createproject-templates/:projectUUID'
              element={<ContractorProjectTemplateList user={user} />}
            />
            <Route
              path='/contractor-createproject-template-details/:projectUUID/template/:templateID'
              element={<ContractorProjectTemplateDetail user={user} />}
            />
            <Route
              path='/contractor-createcontract/:projectUUID'
              element={<ContractorCreateContract user={user} />}
            />
            <Route
              path='/contractor-createdproject-sign/:projectUUID'
              element={<ContractorProjectSign user={user} />}
            />
            <Route
              path='/contractor-createdproject-viewcontract/:projectUUID'
              element={<ContractorProjectViewContracts user={user} />}
            />
            <Route
              path='/contractor-createdproject-takeoff/:projectUUID'
              element={<ContractorProjectTakeoff user={user} />}
            />
            <Route
              path='/contractor-createdproject-takeoff-trades/:projectUUID'
              element={<ContractorProjectTakeoffTrades user={user} />}
            />
            <Route
              path='/contractor-createdproject-files/:projectUUID'
              element={<ContractorProjectFiles user={user} />}
            />
            <Route
              path='/contractor-createdproject-bid/:projectUUID'
              element={<ContractorProjectBid user={user} />}
            />
            <Route
              path='/contractor-createdproject-trade-bid/:projectUUID'
              element={<ContractorProjectSubTradeBid user={user} />}
            />
            <Route
              path='/contractor-createdproject-trade-templates/:projectUUID'
              element={<ContractorProjectTradeTemplates user={user} />}
            />
            <Route
              path='/contractor-createdproject-trade-template-details/:projectUUID/template/:templateID'
              element={<ContractorProjectTradeTemplateDetail user={user} />}
            />
            <Route
              path='/contractor-createdproject-change-orders/:projectUUID'
              element={<ContractorProjectChangeOrders user={user} />}
            />
            <Route
              path='/contractor-createdproject-change-order/:projectUUID/version/:versionID'
              element={<ContractorProjectChangeOrder user={user} />}
            />
            <Route
              path='/contractor-createdproject-change-order-pricing/:projectUUID/version/:versionID'
              element={<ContractorProjectChangeOrderBid user={user} />}
            />
            <Route
              path='/contractor-document/:projectUUID/'
              element={<ContractorDocument user={user} />}
            />

            <Route
              path='/admin-project-comment'
              element={<AdminProjectComment user={user} />}
            />

            <Route
              path="/architect-dashboard"
              element={<ArchitectDashboard user={user} />}
            />
            <Route
              path="/architect-view-question/:questionUUID"
              element={<ArchitectViewQuestion user={user} />}
            />

            <Route
              path="/logout"
              element={<Logout />}
            />
        </Route>*/}
        </Routes>
      </Router>
    </>
  );
}

export default App;
